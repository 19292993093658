.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-45deg, #f8f9fa, #e9ecef, #adb5bd, #6c757d);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  padding: 20px;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.login-container {
  background: white;
  padding: 32px;
  border-radius: 8px;
  width: 100%;
  max-width: 360px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  animation: containerAppear 0.3s ease-out;
}

@keyframes containerAppear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-header {
  text-align: center;
  margin-bottom: 24px;
}

.login-header h1 {
  color: #202124;
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: 400;
}

.login-header p {
  color: #5f6368;
  font-size: 14px;
}

.form-field {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  color: #202124;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  padding: 8px 12px;
  background: white;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 16px;
  color: #202124;
  transition: all 0.2s ease;
  outline: none;
}

.form-input:focus {
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.form-input::placeholder {
  color: #5f6368;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
}

.register-text {
  text-align: center;
  margin-top: 20px;
  color: #5f6368;
  font-size: 14px;
}

@media (max-width: 480px) {
  .login-container {
    padding: 24px;
  }
}
